import React from "react";
import { Breadcrumb } from "react-bootstrap";
import './Breadcrumb.scss';

const PropertyDetailsBreadcrumb = (props) => {
  return (
    <Breadcrumb>
        <Breadcrumb.Item href="/">
            Home
        </Breadcrumb.Item>
        <Breadcrumb.Item href={props.searchType == "sales" ? "/property/for-sale/" : "/property/to-rent/"}>
            {props.searchType == "sales" ? "Buy" : "Rent"} 
        </Breadcrumb.Item>
        <Breadcrumb.Item active>{props?.title}</Breadcrumb.Item>
    </Breadcrumb>
  );
};

export default PropertyDetailsBreadcrumb;
